import React, { Fragment, useEffect, useState } from "react"
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap.css"



const Handle = Slider.Handle
const formatter = new Intl.NumberFormat("de", {
  style: "currency",
  currency: "EUR",
  maximumSignificantDigits: 20,
})

const sliderRef = React.createRef()
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <div>
      {/*<Tooltip*/}
      {/*  prefixCls="rc-slider-tooltip-new"*/}
      {/*  overlay={value<props.max ? formatter.format(value) :  formatter.format(value) + " +" }*/}
      {/*  visible={true}*/}
      {/*  placement="top"*/}
      {/*  // key={index}*/}
      {/*>*/}
        <Handle value={value} {...restProps} />
      {/*</Tooltip>*/}
    </div>

  )
}
const ContactRangeSlider = (props) => {
  const [value, setValue] = useState(0)


  const handleChange = changeEvent => {

    if (parseInt(changeEvent) < parseInt(props.minSlide)) {
      setValue(props.minSlide)
    } else {
      setValue(changeEvent)
      props.onChange(changeEvent)
    }


  }
  useEffect(() => {

      setValue(props.minSlide)
    }, [props.minSlide],
  )

  return (

    <Fragment>
      <div style={{marginBottom: "20px"}} className="text-center">{value==props.max ? formatter.format(value) + " +" :  formatter.format(value)  }</div>
      <Slider
        handleStyle={{
        borderColor: "black",
        height: 22,
        width: 22,
        marginLeft: -3,
        marginTop: -5,
        backgroundColor: "transparent",

      }}
        railStyle={{borderRadius:"0px",backgroundColor:"white", height: 10,  boxShadow: "0 0 10px rgba(38, 38, 38, 0.34)" }}
        trackStyle={{ background: "#fc5c63",height:10,  boxShadow: "0 0 10px rgba(38, 38, 38, 0.34)" }}
        ref={sliderRef}
        min={0}
        max={parseInt(props.max)}
        onChange={handleChange}
        step={100}
        value={parseInt(value)}
        defaultValue={parseInt(value)}
        handle={handle}/>

    </Fragment>
  )
}

export default ContactRangeSlider
