import React from "react"
import {graphql} from "gatsby"
import SEO from "../components/seo"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import Layout from "../components/Layout/layout"
import ContactFormNew from "../components/Contact/ContactFormNew/ContactFormNew"
import NewsletterSection from "../components/NewsletterSection/newsletterSection"
import BackgroundImage from "gatsby-background-image"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"

const askForOfferNew = (props) => {

  const {locale} = props.pageContext

  return (

    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["askForOfferTitle"]} lang={props.pageContext.locale} description={translationHelper[locale]["askForOfferDescription"]}/>
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator pixels={40}/>
            <SectionTitleSeperator h1Override={true} hideTop={true} title={translationHelper[locale]["askForOffer"]}/>
          </Col>
        </Row>
      </Container>
      <BackgroundImage width="100%" fluid={props.data.darkBlueBgImage.childImageSharp.fluid} backgroundColor="#1f2732">
        <SectionSingleVerticalSeperator color="#fff"/>
        <EmptySpaceSeperator/>

        <h2 className="h2TitleStyled text-center mainColor">{translationHelper[locale]["contactForm"]}</h2>
        <p className="pTextStyled colorLight text-center colorLight">{translationHelper[locale]["contactFormText"]}</p>
        <SectionSingleVerticalSeperator color="#fff"/>

      </BackgroundImage>

      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>
            <SectionSingleVerticalSeperator />
            <EmptySpaceSeperator pixels={40}/>
            <ContactFormNew formId="askOfferNew"/>
            <EmptySpaceSeperator pixels={40}/>
            <SectionSingleVerticalSeperator />
          </Col>
        </Row>
      </Container>
      <NewsletterSection
        title="Newsletter"
        titleColor="#fc5c63"
        subtext={translationHelper[locale]["newsletterSubText"]}
        bgColor="#1f2732"
        seperatorColor="#fff"
        fluid={props.data.darkBlueBgImage.childImageSharp.fluid}
      />
      <Container fluid={true} className="p-0">
        <Row noGutters={true}>
          <Col md={12}>
            <h2 className="h2TitleStyled projectNeeded text-center mainColor p-4">{translationHelper[locale]["weDeliverTheProjectYouNeed"]} </h2>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export const askForOfferQuery = graphql`
    query{
        darkBlueBgImage: file(relativePath: {eq: "bg-images/section-darkblue.png"}) {
            childImageSharp {
                fluid(maxWidth:4200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
export default askForOfferNew

