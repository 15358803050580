import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "react-intl"
import ReCAPTCHA from "react-google-recaptcha"
import parse from "html-react-parser"
import Container from "react-bootstrap/Container"
import SectionSingleVerticalSeperator from "../../Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import MiniSectionTitle from "../../Misc/MiniSectionTitle/miniSectionTitle"
import classes from "./ContactFormNew.module.scss"
import EmptySpaceSeperator from "../../../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import Form from "react-bootstrap/Form"
import ContactRangeSlider from "../ContactRangeSlider/contactRangeSlider"
import CustomCheckBox from "../../Misc/CustomCheckBox/customCheckBox"


const ContactFormNew = (props) => {
  const { formatMessage } = props.intl
  const dontCare = formatMessage({ id: "contactFormDontKnowDontCare" })
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [minPrice, setMinPrice] = useState({ price: 0 })
  const [checkedItems, setCheckedItems] = useState()
  const [checkedItemsToArray, setCheckedItemsToArray] = useState([])
  const [isRendered, setIsRendered] = useState(false)
  const [firstRender, setFirsRender] = useState(true)
  const [defaultDropdownValue, setDefaultDropdownValue] = useState({
    projects: "",
    frameworks: formatMessage({ id: "contactFormFrameworkType" }),
    learnedAboutUs: formatMessage({ id: "contactFormLearnedAboutUs" }),
    haveCompany: formatMessage({ id: "contactFormHaveBusiness" }),
  })

  const SERVICES_WEBSITE = "website"
  const SERVICES_ESHOP = "eshop"
  const SERVICES_CUSTOM = "custom"
  const SERVICES_BOOKING = "booking"
  const SERVICES_DIGITAL = "digital"
  const SERVICES_VTOUR = "vtour"


  // Available Frameworks per Service
  const frameworks = {
    [SERVICES_WEBSITE]: ["Wordpress", "Joomla", "Drupal", "Custom", "Custom React.js", dontCare],
    [SERVICES_ESHOP]: ["Wordpress", "Opencart", "Shopify", "Magento", "PrestaShop", "Joomla", "Custom", dontCare],
    [SERVICES_CUSTOM]: ["Laravel", "Symfony", "React.js", "Lumen", dontCare],
    [SERVICES_BOOKING]: ["Wordpress", "Joomla", "Drupal", "Custom", "Custom React.js", dontCare],
    [SERVICES_DIGITAL]: [],
    [SERVICES_VTOUR]: ["0 - 100", "100 - 200", "200 - 300", "300 - 400", "400+"],

  }

  // Min Prices for Basic Services
  const MIN_ESHOP_PRICE = "1500"
  const MIN_WEBSITE_PRICE = "1000"
  const MIN_BOOKING_PRICE = "1250"
  const MIN_DIGITAL_PRICE = "600"
  const MIN_3D_TOUR_PRICE = "200"
  const MIN_CUSTOM_PRICE = "5000"

  const basePriceRanges = {
    [SERVICES_WEBSITE]: MIN_WEBSITE_PRICE,
    [SERVICES_ESHOP]: MIN_ESHOP_PRICE,
    [SERVICES_DIGITAL]: MIN_DIGITAL_PRICE,
    [SERVICES_BOOKING]: MIN_BOOKING_PRICE,
    [SERVICES_CUSTOM]: MIN_CUSTOM_PRICE,
    [SERVICES_VTOUR]: MIN_3D_TOUR_PRICE,
  }

  // Detailed Prices based on Framework
  const priceRanges = {
    [SERVICES_WEBSITE]: {
      "Wordpress": "1000",
      "Joomla": "1000",
      "Drupal": "1250",
      "Custom": "3000",
      "Custom React.js": "5000",
      [dontCare]: MIN_WEBSITE_PRICE,
    },
    [SERVICES_ESHOP]: {
      "Wordpress": "1500",
      "Joomla": "1500",
      "Shopify": "2000",
      "Magento": "3500",
      "PrestaShop": "2500",
      "Opencart": "2000",
      "Custom": "6000",
      [dontCare]: MIN_ESHOP_PRICE,
    },
    [SERVICES_BOOKING]: {
      "Wordpress": "1250",
      "Joomla": "1250",
      "Drupal": "1500",
      "Custom": "4000",
      "Custom React.js": "6000",
      [dontCare]: MIN_BOOKING_PRICE,
    },
    [SERVICES_CUSTOM]: {
      "Laravel": "5000",
      "Symfony": "6000",
      "React.js": "6000",
      "Lumen": "5000",
      [dontCare]: MIN_CUSTOM_PRICE,
    },
    [SERVICES_VTOUR]: {
      "0 - 100": "200",
      "100 - 200": "350",
      "200 - 300": "450",
      "300+": "450",

    },

  }

  // All the groups that have extra features based on websites
  const WEBSITES_GROUPS = [
    "website",
    "eshop",
    "booking",
    "custom",
    "default",
  ]
  // All the groups that have extra features based on websites and social
  const WEBSITE_SOCIAL_GROUP = [
    "website",
    "eshop",
    "booking",
    "custom",
    "digital",
    "default",
  ]
  const CHECKBOX_DEFAULT_MD_CLASS = 4
  const CHECKBOX_DEFAULT_SM_CLASS = 6
  // All Extra services
  const SERVICES_CHECKBOXES = {
    logo: {
      name: "extraServicesLogo",
      group: WEBSITES_GROUPS,
    },
    seo: {
      name: "extraServicesSeo",
      group: WEBSITES_GROUPS,
    },
    pagespeed: {
      name: "extraServicesPSpeed",
      group: WEBSITES_GROUPS,
    },
    extraLang: {
      name: "extraServicesExtraLang",
      group: WEBSITES_GROUPS,
    },
    gdpr: {
      name: "extraServicesGdpr",
      group: WEBSITES_GROUPS,
    },
    branding: {
      name: "extraServicesBranding",
      group: WEBSITES_GROUPS,
    },
    socialMedia: {
      name: "extraServicesSMAdv",
      group: WEBSITE_SOCIAL_GROUP,
    },
    socialMediaProfile: {
      name: "extraServicesSMProfile",
      group: WEBSITE_SOCIAL_GROUP,
    },
    google: {
      name: "extraServicesGoogleAdv",
      group: WEBSITE_SOCIAL_GROUP,
    },
    // cardPayment: {
    //   name: "extraServicesCardPayment",
    //   group: ["eshop"],
    // },
    skroutz: {
      name: "extraServicesSkroutz",
      group: ["eshop"],
    },
    erp: {
      name: "extraServicesERP",
      group: ["eshop"],
    },
    floorPlan: {
      name: "extraServicesFloorPlan",
      group: ["vtour"],
    },
    hotSpots: {
      name: "extraServicesHotspots",
      group: ["vtour"],
    },


  }

  // Options for whereYouFoundUs dropdown
  const FOUND_US_OPTIONS = {
    google: formatMessage({ id: "learnedFromGoogle" }),
    friend: formatMessage({ id: "learnedFromFriend" }),
    facebook: formatMessage({ id: "learnedFromFacebook" }),
    instagram: formatMessage({ id: "learnedFromInstagram" }),
    post: formatMessage({ id: "learnedFromPost" }),
    other: formatMessage({ id: "learnedFromElse" }),

  }

  useEffect(() => {
    let itemsToAppend
    Object.keys(SERVICES_CHECKBOXES).map((service) => {
      itemsToAppend = { ...itemsToAppend, [service]: false }
    })
    setCheckedItems(itemsToAppend)
    setIsRendered(true)

  }, [])

  const [formFrameworks, setFormFrameworks] = useState({
    frameworks: [],
  })

  // Form fields
  const [formFields, setFormFields] = useState({
    name: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
    agree: "",
    projectType: "",
    projectFramework: "",
    price: "",
    extras: [],
    haveWebsite: "",
    haveCompany: "no",
    learnedAboutUs: "",

  })

  const recaptchaRef = React.createRef();
  const formRef = React.createRef();


  const data = useStaticQuery(graphql`
      query {
          darkBlueBgImage: file(relativePath: {eq: "bg-images/section-darkblue.png"}) {
              childImageSharp {
                  fluid(maxWidth:4200) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }`)

  const onRecaptchaChange = e => {

    if (e) {
      setSubmitDisabled(false)
    }
  }

  // When have a company dropdown changes
  const handleHaveCompanyChange = e => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value })
    setDefaultDropdownValue({ ...defaultDropdownValue, haveCompany: e.target.value })
  }

  const handleHowFoundUs = e => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value })
    setDefaultDropdownValue({ ...defaultDropdownValue, learnedAboutUs: e.target.value })
  }
  // when basic inputs change
  const handleChange = e => setFormFields({ ...formFields, [e.target.name]: e.target.value })

  // when services dropdown change
  // Updated the Framework dropdown too
  const handleProjectChange = e => {

    setFirsRender(false)

    // Calculate the base price based on service
    let basePriceFromProject = basePriceRanges[e.target.value]

    // change dropdown value
    setDefaultDropdownValue({
      projects: e.target.value,
      frameworks: e.target.value !== SERVICES_VTOUR ? formatMessage({ id: "contactFormFrameworkType" }) : formatMessage({ id: "contactFormSelectSqm" }),
    })

    // change form fields
    setFormFields({
      ...formFields,
      projectType: e.target.value,
      projectFramework: "",
      price: basePriceFromProject,
    })


    // set the min base price
    setMinPrice({
      price: basePriceFromProject,
    })

    // set the frameworks
    setFormFrameworks({
      frameworks: frameworks[e.target.value],
    })

  }

  // When Framework Changes
  const handleFrameworkChange = e => {

    let priceBasedOnFramework = typeof priceRanges[formFields["projectType"]] !== undefined && typeof priceRanges[formFields["projectType"]][e.target.value] !== undefined ? priceRanges[formFields["projectType"]][e.target.value] : basePriceRanges[formFields["projectType"]]
    setFormFields({
      ...formFields,
      projectFramework: e.target.value,
      price: priceBasedOnFramework,
    })

    // Find the price of Project Types Framework
    setMinPrice({
      price: priceBasedOnFramework,
    })

    // Update Dropdown Value
    setDefaultDropdownValue({
      ...defaultDropdownValue,
      frameworks: e.target.value,
    })

  }

  // Handles the budget range slider
  const handleRangeSliderChange = val => {
    setFormFields({
      ...formFields,
      price: val,
    })
  }

  // Handles when a checkbox is checked or not
  const handleChangeCheckbox = e => {

    const item = e.target.name
    const isChecked = !checkedItems[item]
    setCheckedItems({
      ...checkedItems,
      [item]: isChecked,
    })
    if (isChecked) {
      setCheckedItemsToArray(checkedItemsToArray.concat(item))
    } else {
      setCheckedItemsToArray(checkedItemsToArray.filter(function(arrayItem) {
          return arrayItem !== item
        }),
      )
    }
  }
  // change checkboxGroup
  let checkboxGroup = formFields.projectType !== "" ? formFields.projectType : "default"


  return (
    <Container>
      <Row>
        <Col md={12}>
          <MiniSectionTitle
            className="text-center"> {formatMessage({ id: "contactFormNewContactDetails" })} </MiniSectionTitle>
          <p
            className="text-center font-weight-600 miniSectionParagraph"> {formatMessage({ id: "contactFormNewContactDetailsDescription" })}</p>
          <EmptySpaceSeperator pixels={40}/>
        </Col>
      </Row>
      <form autoComplete="off" ref={formRef} data-netlify-recaptcha="true" action="/contact-sent" id={props.formId}
            name={props.formId}
            method="POST"
            data-netlify="true"
      >
        <Row>
          <Col md={1}/>
          <Col md={10}>
            <Row>
              <Col md={6}>
                <input
                  className={classes.Input}
                  type="text" name="name"
                  id="name"
                  placeholder={formatMessage({ id: "name" }) + " *"}
                  onChange={handleChange}
                />
              </Col>
              <Col md={6}>
                <input
                  className={classes.Input}
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder={formatMessage({ id: "lastName" }) + " *"}
                  onChange={handleChange}

                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <input
                  className={classes.Input}
                  type="text" name="phone"
                  id="phone"
                  required={true}
                  placeholder={formatMessage({ id: "phone" }) + " *"}
                  onChange={handleChange}

                />
              </Col>
              <Col md={6}>
                <input
                  className={classes.Input}
                  type="email" name="email"
                  id="email"
                  required={true}
                  placeholder={formatMessage({ id: "email" }) + " *"}
                  onChange={handleChange}
                />
              </Col>
              {/*<Col md={4}>*/}
              {/*  <input*/}
              {/*    className={classes.Input}*/}
              {/*    type="text"*/}
              {/*    id="haveWebsite"*/}
              {/*    name="haveWebsite"*/}
              {/*    placeholder={formatMessage({ id: "contactFormHaveWebsite" })}*/}
              {/*  />*/}
              {/*</Col>*/}
              {/*<Col md={4}>*/}
              {/*  <Form.Control name="haveCompany" onChange={handleHaveCompanyChange} className={classes.Input}*/}
              {/*                as="select"*/}
              {/*                value={defaultDropdownValue.haveCompany}>*/}
              {/*    <option disabled value={formatMessage({ id: "contactFormHaveBusiness" })}*/}
              {/*            key={-1}>{formatMessage({ id: "contactFormHaveBusiness" })}</option>*/}
              {/*    <option value="yes">{formatMessage({ id: "yes" })}</option>*/}
              {/*    <option value="no">{formatMessage({ id: "no" })}</option>*/}

              {/*  </Form.Control>*/}
              {/*</Col>*/}
              {/*<Col md={4}>*/}
              {/*  <Form.Control name="learnedAboutUs" onChange={handleHowFoundUs} className={classes.Input} as="select"*/}
              {/*                value={defaultDropdownValue.learnedAboutUs}>*/}
              {/*    <option disabled value={formatMessage({ id: "contactFormLearnedAboutUs" })}*/}
              {/*            key={-1}>{formatMessage({ id: "contactFormLearnedAboutUs" })}</option>*/}
              {/*    {*/}
              {/*      isRendered ? Object.keys(FOUND_US_OPTIONS).map((service) => {*/}
              {/*        return (*/}
              {/*          <option value={service}*/}
              {/*                  key={-1}>{FOUND_US_OPTIONS[service]}</option>*/}
              {/*        )*/}

              {/*      }) : null*/}
              {/*    }*/}

              {/*  </Form.Control>*/}
              {/*</Col>*/}
              <EmptySpaceSeperator pixels={40}/>
              <Col md={12}><SectionSingleVerticalSeperator/> </Col>
            </Row>

          </Col>
          <Col md={1}/>
        </Row>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator pixels={40}/>
            <MiniSectionTitle
              className="text-center"> {formatMessage({ id: "contactFormNewProjectInfo" })} </MiniSectionTitle>
            <p
              className="text-center font-weight-600 miniSectionParagraph"> {formatMessage({ id: "contactFormNewProjectInfoDescription" })}</p>
            <EmptySpaceSeperator pixels={40}/>
          </Col>
        </Row>

        <Row>
          <Col md={1}/>
          <Col md={10}>
            <Row>
              <Col md={12}>
                <Form.Control required={true} name="projectType" onChange={handleProjectChange} className={classes.Input} as="select"
                              value={defaultDropdownValue.projects}>
                  <option disabled value=""
                          key={-1}>{formatMessage({ id: "contactFormNewProjectType" }) + " *"}</option>
                  <option value={SERVICES_WEBSITE}>{formatMessage({ id: "contactFormWebsite" })}</option>
                  <option value={SERVICES_ESHOP}>{formatMessage({ id: "contactFormEshop" })}</option>
                  <option value={SERVICES_DIGITAL}>Digital Marketing</option>
                  {/*<option value={SERVICES_CUSTOM}>{formatMessage({ id: "contactFormCustomProject" })}</option>*/}
                  {/*<option value={SERVICES_BOOKING}>{formatMessage({ id: "contactFormBooking" })}</option>*/}
                  {/*<option value={SERVICES_VTOUR}>3D Virtual Tour</option>*/}
                </Form.Control>
              </Col>
              {/*<Col md={6}>*/}
              {/*  {*/}
              {/*    formFrameworks.frameworks && formFrameworks.frameworks.length > 1 || firstRender ?*/}
              {/*      <Form.Control name="projectFramework" onChange={handleFrameworkChange} className={classes.Input}*/}
              {/*                    as="select"*/}
              {/*                    value={defaultDropdownValue.frameworks}>*/}
              {/*        {*/}
              {/*          formFields.projectType === SERVICES_VTOUR ?*/}
              {/*            <option disabled value={formatMessage({ id: "contactFormSelectSqm" })}*/}
              {/*                    key={-1}>{formatMessage({ id: "contactFormSelectSqm" })}</option> :*/}
              {/*            <option disabled value={formatMessage({ id: "contactFormFrameworkType" })}*/}
              {/*                    key={-1}>{formatMessage({ id: "contactFormFrameworkType" })}</option>*/}
              {/*        }*/}

              {/*        {*/}
              {/*          formFrameworks.frameworks && formFrameworks.frameworks.length > 1 ?*/}
              {/*            formFrameworks.frameworks.map(function(frame) {*/}
              {/*              return <option key={frame} value={frame}>{frame}</option>*/}
              {/*            })*/}
              {/*            : null*/}
              {/*        }*/}
              {/*      </Form.Control>*/}
              {/*      : null*/}
              {/*  }*/}
              {/*</Col>*/}
            </Row>
            <Row>
              <Col md={12}>
                 <textarea
                   className={classes.Input}
                   style={{height:"auto"}}
                   placeholder={formatMessage({ id: "contactFormMessageArea" })}
                   id="message"
                   rows="3"
                   name="message"
                   onChange={handleChange}
                 />
              </Col>
              <EmptySpaceSeperator pixels={80}/>
              {/*<Col md={12}><SectionSingleVerticalSeperator/> </Col>*/}
              <EmptySpaceSeperator pixels={40}/>
            </Row>
          </Col>
          <Col md={1}/>
        </Row>

        {/*<Row>*/}
        {/*  <Col md={12}>*/}
        {/*    <EmptySpaceSeperator pixels={40}/>*/}
        {/*    <MiniSectionTitle*/}
        {/*      className="text-center"> {formatMessage({ id: "contactFormNewServices" })} </MiniSectionTitle>*/}
        {/*    <p*/}
        {/*      className="text-center font-weight-600 miniSectionParagraph"> {formatMessage({ id: "contactFormNewDescription" })}</p>*/}
        {/*    <EmptySpaceSeperator pixels={40}/>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        {/*<Row>*/}
        {/*  <Col md={1}/>*/}
        {/*  <Col md={10}>*/}
        {/*    <Row>*/}
        {/*      {*/}
        {/*        isRendered ? Object.keys(SERVICES_CHECKBOXES).map((service) => {*/}
        {/*          if (SERVICES_CHECKBOXES[service].group.includes(checkboxGroup)) {*/}
        {/*            return (*/}
        {/*              <CustomCheckBox key={service} label={formatMessage({ id: SERVICES_CHECKBOXES[service].name })}*/}
        {/*                              md={CHECKBOX_DEFAULT_MD_CLASS} name={service} checked={checkedItems[service]}*/}
        {/*                              sm={CHECKBOX_DEFAULT_SM_CLASS}*/}
        {/*                              onChange={handleChangeCheckbox}/>*/}
        {/*            )*/}
        {/*          }*/}
        {/*        }) : null*/}
        {/*      }*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*  <Col md={12}>*/}
        {/*    <EmptySpaceSeperator pixels={40}/>*/}
        {/*    <Col md={12}><SectionSingleVerticalSeperator/> </Col>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        {/*<Row>*/}
        {/*  <Col md={12}>*/}
        {/*    <EmptySpaceSeperator pixels={40}/>*/}
        {/*    <MiniSectionTitle*/}
        {/*      className="text-center"> {formatMessage({ id: "contactFormNewBudget" })} </MiniSectionTitle>*/}
        {/*    <p*/}
        {/*      className="text-center font-weight-600 miniSectionParagraph"> {formatMessage({ id: "contactFormNewBudgetDescription" })}</p>*/}
        {/*    <EmptySpaceSeperator pixels={40}/>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        {/*<Row>*/}
        {/*  <Col md={1}/>*/}
        {/*  <Col md={10}>*/}
        {/*    <Row>*/}
        {/*      <Col md={12}>*/}
        {/*        <ContactRangeSlider minSlide={minPrice.price} onChange={handleRangeSliderChange} min={0} max="10000"/>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*  <Col md={1}/>*/}
        {/*  <EmptySpaceSeperator pixels={40}/>*/}
        {/*</Row>*/}

        <Row style={{ marginTop: "20px" }}>
          <Col className="text-center" md={12}>
            <ReCAPTCHA
              className="recaptchaContainer"
              ref={recaptchaRef}
              size="normal"
              onChange={onRecaptchaChange}
              sitekey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "50px" }}>
          <Col md={1}/>
          <Col md={10}>

            <Row>
              <Col md={12}>
                <input className={classes.SubmitBtn} disabled={submitDisabled} type="submit"
                       value={formatMessage({ id: "extraServiceSend" })}/>
                <EmptySpaceSeperator pixels={60}/>
              </Col>
            </Row>

            <Row>
              <Col md={1}/>
              <Col md={10}>
                <input type="checkbox"
                       name="agree"
                       value="check"
                       required={true}
                       onChange={handleChange}
                       id="agree"/>

                <label className={classes.CheckBoxLabel}>
                  {
                    parse(formatMessage({ id: "contactFormConsent" }))
                  }
                </label>
              </Col>
            </Row>
            <EmptySpaceSeperator/>
          </Col>
          <Col md={1}/>
        </Row>

        <input type="hidden" name="form-name" value={props.formId}/>
        <input type="hidden" name="budget" value={formFields.price}/>
        <input type="hidden" name="extra-services"
               value={isRendered && checkedItemsToArray !== undefined && checkedItemsToArray.length > 0 ? checkedItemsToArray.map(service => {
                 return formatMessage({ id: SERVICES_CHECKBOXES[service].name })
               }).join(", ") : ""}/>

      </form>
    </Container>
  )
}

export default injectIntl(ContactFormNew)
