import React from "react"
import styles from "./miniSectionTitle.module.scss"
const MiniSectionTitle = (props) => {
  const classes = [props.className,styles.miniSectionTitle].join(" ");
  return (
    <h3 className={classes}>{props.children}</h3>
  )
}

export default MiniSectionTitle
